<template>
  <div id="recallPlanReport">
    <el-dialog
      :title="recallPlanReportFormTitle"
      width="1500px"
      :visible.sync="recallPlanReportDialogVisible"
      :close-on-click-modal="false"
      @close="recallPlanReportDialogClose"
    >
      <el-form
        ref="recallPlanReportFormRef"
        :model="recallPlanReportForm"
        :rules="recallPlanReportFormRules"
        label-position="right"
        label-width="160px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="recallPlanReportForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="注册证号码" prop="registrationNo">
              <el-input v-model="recallPlanReportForm.registrationNo" placeholder="请输入注册证号码" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="生产企业" prop="manufacturer">
              <el-input v-model="recallPlanReportForm.manufacturer" placeholder="请输入生产企业" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="中国境内负责单位、负责人及联系方式" prop="unitPrincipalPhone">
              <el-input
                v-model="recallPlanReportForm.unitPrincipalPhone"
                placeholder="请输入中国境内负责单位、负责人及联系方式"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回工作联系人和联系方式" prop="recallWorkContactsPhone">
              <el-input
                v-model="recallPlanReportForm.recallWorkContactsPhone"
                placeholder="请输入召回工作联系人和联系方式"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="应当通知人数" prop="shouldNotifiedNumber">
              <el-input v-model="recallPlanReportForm.shouldNotifiedNumber" placeholder="请输入应当通知人数" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="已通知人数" prop="notifiedNumber">
              <el-input v-model="recallPlanReportForm.notifiedNumber" placeholder="请输入已通知人数" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="通知时间" prop="notificationTime">
              <el-date-picker v-model="recallPlanReportForm.notificationTime" placeholder="请选择通知时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="通知方式" prop="notificationWay">
              <el-input v-model="recallPlanReportForm.notificationWay" placeholder="请输入通知方式" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="其他应当通知人数" prop="otherShouldNotifiedNumber">
              <el-input v-model="recallPlanReportForm.otherShouldNotifiedNumber" placeholder="请输入其他应当通知人数" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="其他已通知人数" prop="otherNotifiedNumber">
              <el-input v-model="recallPlanReportForm.otherNotifiedNumber" placeholder="请输入其他已通知人数" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="其他通知时间" prop="otherNotificationTime">
              <el-date-picker v-model="recallPlanReportForm.otherNotificationTime" placeholder="请选择其他通知时间" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="其他通知方式" prop="otherNotificationWay">
              <el-input v-model="recallPlanReportForm.otherNotificationWay" placeholder="请输入其他通知方式" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="应当召回数量" prop="shouldBeRecalledQuantity">
              <el-input v-model="recallPlanReportForm.shouldBeRecalledQuantity" placeholder="请输入应当召回数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="已完成数量" prop="completedQuantity">
              <el-input v-model="recallPlanReportForm.completedQuantity" placeholder="请输入已完成数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="有效性检查情况" prop="validityCheckStatus">
              <el-input v-model="recallPlanReportForm.validityCheckStatus" placeholder="请输入有效性检查情况" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="召回产品的处理措施" prop="measure">
              <el-input
                v-model="recallPlanReportForm.measure"
                placeholder="请输入召回产品的处理措施"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="完成召回需要时间估计" prop="estimatedTime">
              <el-input
                v-model="recallPlanReportForm.estimatedTime"
                placeholder="请输入完成召回需要时间估计"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="其他情况" prop="otherSituations">
              <el-input
                v-model="recallPlanReportForm.otherSituations"
                placeholder="请输入其他情况"
                type="textarea"
                :rows="3"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="报告单位" prop="reportingUnit">
              <el-input v-model="recallPlanReportForm.reportingUnit" placeholder="请输入报告单位" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="报告人" prop="reporter">
              <el-input v-model="recallPlanReportForm.reporter" placeholder="请输入报告人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="负责人" prop="principal">
              <el-input v-model="recallPlanReportForm.principal" placeholder="请输入负责人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="报告日期" prop="reportDate">
              <el-date-picker v-model="recallPlanReportForm.reportDate" placeholder="请选择报告日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="recallPlanReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="recallPlanReportFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.productName" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="recallPlanReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="registrationNo" label="注册证号码" />
      <el-table-column prop="manufacturer" label="生产企业" />
      <el-table-column prop="unitPrincipalPhone" label="中国境内负责单位、负责人及联系方式" />
      <el-table-column prop="recallWorkContactsPhone" label="召回工作联系人和联系方式" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="recallPlanReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addRecallPlanReport, deleteRecallPlanReport, updateRecallPlanReport, selectRecallPlanReportInfo, selectRecallPlanReportList } from '@/api/universal/recallPlanReport'

export default {
  data () {
    return {
      recallPlanReportDialogVisible: false,
      recallPlanReportFormTitle: '',
      recallPlanReportForm: {
        id: '',
        productName: '',
        registrationNo: '',
        manufacturer: '',
        unitPrincipalPhone: '',
        recallWorkContactsPhone: '',
        shouldNotifiedNumber: '',
        notifiedNumber: '',
        notificationTime: '',
        notificationWay: '',
        otherShouldNotifiedNumber: '',
        otherNotifiedNumber: '',
        otherNotificationTime: '',
        otherNotificationWay: '',
        shouldBeRecalledQuantity: '',
        completedQuantity: '',
        validityCheckStatus: '',
        measure: '',
        estimatedTime: '',
        otherSituations: '',
        reportingUnit: '',
        reporter: '',
        principal: '',
        reportDate: ''
      },
      recallPlanReportFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      recallPlanReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      }
    }
  },
  created () {
    selectRecallPlanReportList(this.searchForm).then(res => {
      this.recallPlanReportPage = res
    })
  },
  methods: {
    recallPlanReportDialogClose () {
      this.$refs.recallPlanReportFormRef.resetFields()
    },
    recallPlanReportFormSubmit () {
      if (this.recallPlanReportFormTitle === '召回计划实施情况报告详情') {
        this.recallPlanReportDialogVisible = false
        return
      }
      this.$refs.recallPlanReportFormRef.validate(async valid => {
        if (valid) {
          if (this.recallPlanReportFormTitle === '新增召回计划实施情况报告') {
            await addRecallPlanReport(this.recallPlanReportForm)
          } else if (this.recallPlanReportFormTitle === '修改召回计划实施情况报告') {
            await updateRecallPlanReport(this.recallPlanReportForm)
          }
          this.recallPlanReportPage = await selectRecallPlanReportList(this.searchForm)
          this.recallPlanReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.recallPlanReportFormTitle = '新增召回计划实施情况报告'
      this.recallPlanReportDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteRecallPlanReport(row.id)
        if (this.recallPlanReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.recallPlanReportPage = await selectRecallPlanReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.recallPlanReportFormTitle = '修改召回计划实施情况报告'
      this.recallPlanReportDialogVisible = true
      this.selectRecallPlanReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.recallPlanReportFormTitle = '召回计划实施情况报告详情'
      this.recallPlanReportDialogVisible = true
      this.selectRecallPlanReportInfoById(row.id)
    },
    selectRecallPlanReportInfoById (id) {
      selectRecallPlanReportInfo(id).then(res => {
        this.recallPlanReportForm.id = res.id
        this.recallPlanReportForm.productName = res.productName
        this.recallPlanReportForm.registrationNo = res.registrationNo
        this.recallPlanReportForm.manufacturer = res.manufacturer
        this.recallPlanReportForm.unitPrincipalPhone = res.unitPrincipalPhone
        this.recallPlanReportForm.recallWorkContactsPhone = res.recallWorkContactsPhone
        this.recallPlanReportForm.shouldNotifiedNumber = res.shouldNotifiedNumber
        this.recallPlanReportForm.notifiedNumber = res.notifiedNumber
        this.recallPlanReportForm.notificationTime = res.notificationTime
        this.recallPlanReportForm.notificationWay = res.notificationWay
        this.recallPlanReportForm.otherShouldNotifiedNumber = res.otherShouldNotifiedNumber
        this.recallPlanReportForm.otherNotifiedNumber = res.otherNotifiedNumber
        this.recallPlanReportForm.otherNotificationTime = res.otherNotificationTime
        this.recallPlanReportForm.otherNotificationWay = res.otherNotificationWay
        this.recallPlanReportForm.shouldBeRecalledQuantity = res.shouldBeRecalledQuantity
        this.recallPlanReportForm.completedQuantity = res.completedQuantity
        this.recallPlanReportForm.validityCheckStatus = res.validityCheckStatus
        this.recallPlanReportForm.measure = res.measure
        this.recallPlanReportForm.estimatedTime = res.estimatedTime
        this.recallPlanReportForm.otherSituations = res.otherSituations
        this.recallPlanReportForm.reportingUnit = res.reportingUnit
        this.recallPlanReportForm.reporter = res.reporter
        this.recallPlanReportForm.principal = res.principal
        this.recallPlanReportForm.reportDate = res.reportDate
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectRecallPlanReportList(this.searchForm).then(res => {
        this.recallPlanReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectRecallPlanReportList(this.searchForm).then(res => {
        this.recallPlanReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectRecallPlanReportList(this.searchForm).then(res => {
        this.recallPlanReportPage = res
      })
    }
  }
}
</script>

<style>
</style>
